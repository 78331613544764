import { createRouter, createWebHistory } from 'vue-router';
import { routes } from '../views';

routes.push({
  // path: '/:catchAll(.*)',
  path: '/:pathMatch(.*)*',
  redirect: '/',
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
