export default [
  {
    path: '/totem',
    name: 'totem',
    component: () => import(/* webpackChunkName: "panel" */ './Main.vue'),
    meta: {
      layout: 'device',
    },
  },
  {
    path: '/totem/:id',
    component: () => import(/* webpackChunkName: "panel" */ './Main.vue'),
    meta: {
      layout: 'device',
    },
  },
];
