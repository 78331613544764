<template>
  <div class="sidebar">
    <nav>
      <router-link to="/panels" class="menu-item">
        <fa-icon :icon="['fad', 'tv']" class="menu-icon" />
        <span class="menu-label">Telas</span>
      </router-link>
      <router-link to="/" class="menu-item">
        <fa-icon :icon="['fad', 'user-headset']" class="menu-icon" />
        <span class="menu-label">Atendimento</span>
      </router-link>
      <router-link to="/tickets" class="menu-item">
        <fa-icon :icon="['fad', 'ticket']" class="menu-icon" />
        <span class="menu-label">Senhas</span>
      </router-link>
      <router-link to="/speaker" class="menu-item">
        <fa-icon :icon="['fad', 'microphone']" class="menu-icon" />
        <span class="menu-label">Controle de Voz</span>
      </router-link>
      <router-link to="/settings" class="menu-item">
        <fa-icon :icon="['fad', 'cogs']" class="menu-icon" />
        <span class="menu-label">Configurações</span>
      </router-link>
    </nav>
  </div>
</template>

<script>
export default {
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.master-layout {
  .sidebar {
    background-color: $light-color;
    width: $sidebar-size;

    nav {
      margin-top: $layout-spacing-lg;
      text-align: center;

      a {
        color: $dark-color;
        display: block;
        transition: color .15s ease;
        margin: $layout-spacing 0;

        &:hover,
        &:focus {
          box-shadow: none;
          text-decoration: none;
        }
      }

      .menu-icon {
        color: $gray-color-dark;
        height: $control-icon-size * 2;
        margin-top: $layout-spacing-lg;
        width: $control-icon-size * 2;
      }

      .menu-label {
        display: block;
        font-size: $font-size;
        text-align: center;
      }

      .router-link-exact-active {
        color: $primary-color;

        .menu-icon {
          color: $primary-color;
        }
      }
    }
  }
}
</style>
