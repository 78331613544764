import attendance from './attendance/routes';
import panel from './panel/routes';
import settings from './settings/routes';
import preference from './preference/routes';
import login from './login/routes';
import panels from './panels/routes';
import totem from './totem/routes';
import tickets from './tickets/routes';
import speaker from './speaker/routes';

export default [
  ...attendance,
  ...panel,
  ...settings,
  ...preference,
  ...login,
  ...panels,
  ...totem,
  ...tickets,
  ...speaker,
];
