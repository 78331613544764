export default [
  {
    path: '/panel/:id',
    name: 'panel',
    component: () => import(/* webpackChunkName: "panel" */ './Main.vue'),
    meta: {
      layout: 'device',
    },
  },
];
