<template>
  <div class="date-nav-bar">
    <div class="date-nav-wrapper">
      <template v-if="loading">
        <div class="loading" />
      </template>
      <template v-else>
        <button class="btn btn-icon btn-prev" @click="prev" :disabled="!canPrev">
          <fa-icon :icon="['far', 'chevron-left']" />
        </button>
        <span class="date" @click.stop="toggleCalendar">{{ formatted }}</span>
        <button class="btn btn-icon btn-next" @click="next" :disabled="!canNext">
          <fa-icon :icon="['far', 'chevron-right']" />
        </button>
      </template>
    </div>
<!--    <dx-calendar-->
<!--      v-if="showCalendar"-->
<!--      :value="value"-->
<!--      @input="onCalendarInput"-->
<!--      :min="min"-->
<!--      :max="max"-->
<!--      :days-of-week="['D', 'S', 'T', 'Q', 'Q', 'S', 'S']"-->
<!--      v-click-outside="closeCalendar">-->
<!--      <mp-svg-icon icon="angle-left" slot="prev-nav" />-->
<!--      <mp-svg-icon icon="angle-right" slot="next-nav" />-->
<!--    </dx-calendar>-->
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    modelValue: {
      type: Date,
    },
    min: {
      type: Date,
    },
    max: {
      type: Date,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      showCalendar: false,
    };
  },
  methods: {
    toggleCalendar() {
      if (!this.disabled) {
        this.showCalendar = !this.showCalendar;
      }
    },
    closeCalendar() {
      this.showCalendar = false;
    },
    onCalendarInput(date) {
      this.showCalendar = false;
      this.$emit('update:modelValue', date);
    },
    next() {
      this.showCalendar = false;
      this.$emit(
        'update:modelValue',
        moment(this.modelValue).add(1, 'day').toDate(),
      );
    },
    prev() {
      this.showCalendar = false;
      this.$emit(
        'update:modelValue',
        moment(this.modelValue).subtract(1, 'day').toDate(),
      );
    },
  },
  computed: {
    canNext() {
      return !this.disabled && (
        !this.max || moment(this.modelValue).isBefore(this.max)
      );
    },
    canPrev() {
      return !this.disabled && (
        !this.min || moment(this.modelValue).isAfter(this.min)
      );
    },
    formatted() {
      const formatted = moment(this.modelValue)
        .format('dddd, DD [de] MMMM [de] YYYY')
        .replace('-feira', '')
        .toLowerCase();

      return formatted.charAt(0).toUpperCase() + formatted.substring(1);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables";

.date-nav-bar {
  background-color: $light-color;
  border-radius: $border-radius;
  border: $border-width solid $border-color;
  position: relative;
  .date-nav-wrapper {
    align-items: center;
    justify-content: center;
    display: flex;
    height: 2.5rem;
    .date {
      color: $body-font-color;
      cursor: pointer;
      flex: 1 0 auto;
      font-size: $font-size-lg;
      text-align: center;
      @media screen and (max-width: $size-sm) {
        font-size: $font-size;
      }
      @media screen and (max-width: $size-xs) {
        font-size: $font-size-sm;
      }
    }
    .btn, .btn:hover, .btn:focus {
      background: transparent;
      box-shadow: none;
      color: $primary-color;
      border: 0;
      height: auto;
      padding: 1rem;
    }
    .svg-icon {
      width: $layout-spacing;
    }
  }
  .calendar {
    position: absolute;
    top: 105%;
    left: 50%;
    transform: translateX(-50%);
    z-index: $zindex-1;
    .btn-action {
      &:first-child {
        text-align: left;
      }
      &:last-child {
        text-align: right;
      }
      .svg-icon {
        color: $body-font-color;
        margin: 0 .5rem;
        width: .35rem;
      }
    }
  }
}
</style>
