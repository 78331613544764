import address from './address';
import avatarLetters from './avatarLetters';
import { capitalize, capitalizeName, capitalizeWord } from './capitalize';
import cep from './cep';
import cnpj from './cnpj';
import cpf from './cpf';
import currency from './currency';
import number from './number';
import date from './date';
import dateOld from './dateOld';
import pad from './pad';
import phone from './phone';
import tuss from './tuss';

export default (app) => {
  app.config.globalProperties.$filters = {
    address,
    avatarLetters,
    capitalize,
    capitalizeName,
    capitalizeWord,
    cep,
    cpf,
    cnpj,
    currency,
    number,
    date,
    dateOld,
    pad,
    phone,
    tuss,
  };
};
