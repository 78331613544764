export default [
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "settings" */ './Main.vue'),
    meta: {
      layout: 'device',
    },
  },
];
