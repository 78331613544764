<template>
  <div class="empty">
    <div class="empty-icon">
      <fa-icon :icon="icon" size="3x"/>
    </div>
    <p class="empty-title h5">{{ title }}</p>
    <p class="empty-subtitle">{{ subtitle }}</p>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: Array,
    },
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
  },
};
</script>

<style lang="scss">
.empty {
  background-color: transparent!important;
}
</style>
