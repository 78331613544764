<template>
  <figure
    class="avatar"
    :class="classes"
    :data-initial="dataInitial"
  >
    <img :src="imageUrl" :alt="name" v-if="imageUrl">
    <fa-icon :icon="icon" v-if="icon" />
  </figure>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
    },
    imageUrl: {
      type: String,
    },
    icon: {
      type: Array,
    },
    size: {
      type: String,
    },
  },
  computed: {
    classes() {
      const classNames = [];
      if (this.size) {
        classNames.push(`avatar-${this.size}`);
      }
      if (this.icon) {
        classNames.push('is-icon');
      }
      return classNames;
    },
    dataInitial() {
      return this.name && !this.imageUrl && !this.icon
        ? this.$filters.avatarLetters(this.name)
        : '';
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.avatar {
  &.avatar-male {
    background-color: $info-color;
  }
  &.avatar-female {
    background-color: $error-color;
  }
  &.is-icon {
    align-items: center;
    display: flex;
    justify-content: center;
    svg {
      font-size: $font-size-lg;
    }
    &.avatar-lg {
      svg {
        font-size: $unit-6;
      }
    }
  }
}
</style>
