export default [
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import(/* webpackChunkName: "panel" */ './Main.vue'),
    meta: {
      layout: 'master',
    },
  },
];
