export default [
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ './Main.vue'),
    meta: {
      layout: 'master',
    },
  },
];
