<template>
  <teleport to="body">
    <transition name="modal">
      <div class="modal active" :class="classes" v-bind="$attrs" v-if="modelValue">
        <div class="modal-overlay" @click="overlayClick"></div>
        <div class="modal-container">
          <div class="modal-header">
            <button class="btn btn-clear float-right" v-if="closable" @click="close" />
            <slot name="header">
              <div class="modal-title pb-2" v-if="title">{{ title }}</div>
            </slot>
          </div>

          <div class="modal-body">
            <slot></slot>
          </div>

          <div class="modal-footer" v-if="hasFooter">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
import { randomHash } from '@/utils/app';

export default {
  inheritAttrs: false,
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
    },
    closable: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: null,
    },
    clickToClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      hash: randomHash(),
    };
  },
  mounted() {
  },
  computed: {
    classes() {
      const classes = [];
      if (this.size !== null) {
        classes.push(`modal-${this.size}`);
      }
      return classes;
    },
    hasFooter() {
      return 'footer' in this.$slots;
    },
  },
  methods: {
    overlayClick() {
      if (this.clickToClose) {
        this.close();
      }
    },
    close() {
      if (this.closable) {
        this.$emit('update:modelValue', false);
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.modal.active {
  &.modal-enter-active {
    transition: all .15s;
  }

  &.modal-leave-active {
    transition: all .3s;
  }

  &.modal-enter,
  &.modal-leave-to {
    opacity: 0;
  }
}

.modal-xl {
  .modal-container {
    max-width: 80%;
  }
}
</style>
