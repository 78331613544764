export function isVisible() {
  return !document.hidden;
}

export function getPlatform() {
  if (window.device && window.device.platform) {
    return window.device.platform;
  }
  return undefined;
}

export function isAndroid() {
  const platform = getPlatform();
  return platform && /android/i.test(platform);
}

export function interval(fn, delay = 1) {
  return {
    intervalId: null,
    start() {
      this.intervalId = setInterval(() => {
        if (isVisible()) {
          fn();
        }
      }, 1000 * delay);
      return this;
    },
    stop() {
      clearInterval(this.intervalId);
    },
  };
}

export function randomHash() {
  return Math.random().toString(32).slice(-8);
}

export function normalizeUrl(url, protocol = 'https') {
  return url && url.startsWith('//') ? `${protocol}:${url}` : url;
}

export function toggleFullScreen() {
  const doc = window.document;
  const docEl = doc.documentElement;

  const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen
    || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
  const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen
    || doc.webkitExitFullscreen || doc.msExitFullscreen;

  if (
    !doc.fullscreenElement && !doc.mozFullScreenElement
    && !doc.webkitFullscreenElement && !doc.msFullscreenElement
  ) {
    requestFullScreen.call(docEl);
  } else {
    cancelFullScreen.call(doc);
  }
}
