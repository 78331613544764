import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Avatar from './common/Avatar.vue';
import Bar from './common/Bar.vue';
import Page from './common/Page.vue';
import DateNavBar from './common/DateNavBar.vue';
import Empty from './common/Empty.vue';
import FormError from './common/FormError.vue';
import InputDate from './common/InputDate.vue';
import Modal from './common/Modal.vue';
import Tile from './common/Tile.vue';

export default (app) => {
  app.component('fa-icon', FontAwesomeIcon);
  app.component('st-avatar', Avatar);
  app.component('st-bar', Bar);
  app.component('st-date-nav-bar', DateNavBar);
  app.component('st-empty', Empty);
  app.component('st-form-error', FormError);
  app.component('st-input-date', InputDate);
  app.component('st-modal', Modal);
  app.component('st-page', Page);
  app.component('st-tile', Tile);
};
