import axios from 'axios';
import { authenticationApiUrl } from '@/config';
import interceptors from './interceptors';

export const authenticationApi = axios.create({
  baseURL: authenticationApiUrl,
  timeout: 90000,
});

export function setToken(token) {
  localStorage.setItem('token', token);
  authenticationApi.defaults.headers.common.Authorization = token;
}

export default {
  install(app, { router }) {
    interceptors(authenticationApi, router);
    app.config.globalProperties.$authenticationService = authenticationApi;
  },
};
