<template>
  <div class="page" :class="{ fill }">
    <teleport to="#page-title" v-if="title">{{ title }}</teleport>
    <slot name="fixed"></slot>
    <div class="loading loading-lg" v-if="loading"></div>
    <slot v-else></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    fill: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.page {
  &.fill {
    height: 100%;
    display: flex;
    flex-direction: column;
    .page-header {
      //
    }
    .page-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-y: auto;
    }
    .page-footer {
      //
    }
  }
}
</style>
