import axios from 'axios';
import { ticketApiUrl } from '@/config';
import interceptors from './interceptors';

export const ticketApi = axios.create({
  baseURL: ticketApiUrl,
  timeout: 90000,
});

export function setToken(token) {
  ticketApi.defaults.headers.common.Authorization = `JWT ${token}`;
}

export default {
  install(app, { router }) {
    interceptors(ticketApi, router);
    app.config.globalProperties.$ticketService = ticketApi;
  },
};
