export default [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "attendance" */ './Main.vue'),
    meta: {
      layout: 'master',
    },
  },
];
