<template>
  <component :is="currentComponent"></component>
</template>

<script>
import DeviceLayout from '@/layouts/Device.vue';
import MasterLayout from '@/layouts/Master.vue';

export default {
  name: 'App',
  data() {
    return {
      layout: null,
    };
  },
  components: {
    DeviceLayout,
    MasterLayout,
  },
  mounted() {
    this.setLayout(this.$route);
  },
  watch: {
    $route(to) {
      this.setLayout(to);
    },
  },
  computed: {
    currentComponent() {
      return this.layout !== null ? `${this.layout}-layout` : 'router-view';
    },
  },
  methods: {
    setLayout(route) {
      this.layout = route.meta.layout || null;
    },
  },
};
</script>
