import moment from 'moment';
import hospitalService from './hospital';
import realtimePlugin from './realtime';
import ticketService from './ticket';
import authenticationService from './authentication/index';

export default (app, options) => {
  moment.locale('pt-BR');
  app.use(ticketService, options);
  app.use(realtimePlugin, options);
  app.use(hospitalService, options);
  app.use(authenticationService, options);
};
