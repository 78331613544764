export default (http, router) => {
  http.interceptors.request.use((request) => {
    request.headers.scope = 'hospital';

    return request;
  });
  http.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && [401].indexOf(error.response.status) > -1) {
        localStorage.setItem('token', '');
        router.push({ path: '/login' });
      }

      return Promise.reject(error);
    },
  );
};
