import StringMask from 'string-mask';

const mask = new StringMask('00.00.00.00');

export default (item) => {
  if (!item) {
    return '';
  }

  const value = item.toString().replace(/\D/g, '');

  return mask.apply(value);
};
