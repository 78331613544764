import moment from 'moment';

export default (value, format = 'date', utc = false) => {
  if (!value) {
    return '';
  }

  let outputFormat;

  switch (format) {
    case 'date':
      outputFormat = 'L';
      break;
    case 'datetime':
      outputFormat = 'L LT';
      break;
    default:
      outputFormat = format || 'L';
  }

  const date = utc ? moment(value).utc() : moment(value);

  return date.isValid() ? date.format(outputFormat) : '';
};
