import axios from 'axios';
import { hospitalApiUrl } from '@/config';
import interceptors from './interceptors';

export const hospitalApi = axios.create({
  baseURL: hospitalApiUrl,
  timeout: 90000,
});

export function setToken(token) {
  hospitalApi.defaults.headers.common.Authorization = `Authorization ${token}`;
}

export default {
  install(app, { router }) {
    interceptors(hospitalApi, router);
    app.config.globalProperties.$hospitalService = hospitalApi;
  },
};
