function getDefaultApiUrl() {
  const { protocol, hostname } = document.location;
  return `${protocol}//${hostname}:3000`;
}

// eslint-disable-next-line import/prefer-default-export
export const hospitalApiUrl = process.env.VUE_APP_ROOT_HOSPITAL_API;

export const apiUrl = process.env.VUE_APP_ROOT_API
  ? process.env.VUE_APP_ROOT_API
  : getDefaultApiUrl();

export const pubPublishKey = process.env.VUE_APP_ROOT_PUBNUB_PUBLISH_KEY;

export const pubSubscribeKey = process.env.VUE_APP_ROOT_PUBNUB_SUBSCRIBE_KEY;

export const authenticationApiUrl = process.env.VUE_APP_ROOT_AUTHENTICATION_API;

export const ticketApiUrl = process.env.VUE_APP_ROOT_TICKET_API;

export const printerModels = [
  'MP-4200 TH',
  'MP-2800 TH',
  'MP-2500 TH',
  'MP-2100 TH',
  'MP-4000 TH',
  'MP-5100 TH',
  'MP-100S TH',
  'MP-20 MI',
  'TM-T20',
  'TM-T81',
  'TM-T88',
  'I9',
  'I7',
  'VOX',
  'VOX+',
  'NIX',
  'DR800',
  'DR700',
  'DR600',
  'DS348',
  'DS300',
  'TSP-143',
  'IM453',
  'IM433',
  'IM402',
  'IM113',
  'IM833',
  'SI-300L',
  'SI-300S',
  'SI-300W',
  'SI-250',
  'E-3202',
  'DT200',
  'PrintiD',
  'PrintiD-Touch',
  'PertoPrinter',
  'CMP-20',
  'POS-80',
  'POS-58',
];
