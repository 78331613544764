<template>
  <template-header></template-header>
  <section class="primary">
    <template-sidebar></template-sidebar>
    <main class="main">
      <router-view/>
    </main>
  </section>
</template>

<script>
import TemplateHeader from './master/Header.vue';
import TemplateSidebar from './master/Sidebar.vue';

export default {
  components: {
    TemplateHeader,
    TemplateSidebar,
  },
  data() {
    return {
      ready: false,
    };
  },
  mounted() {
    this.ready = true;
    document.body.classList.add('master-layout');
  },
  unmounted() {
    document.body.classList.remove('master-layout');
  },
  methods: {
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.master-layout {
  background-color: $bg-color;
  #app {
    display: grid;
    grid-template-rows: $header-size auto;
    height: 100vh;
  }
  .primary {
    display: grid;
    grid-template-columns: $sidebar-size auto;
    min-height: 1px;
  }
  .main-nav {
    border-bottom: $border-width solid $border-color;
  }
  .sidebar {
    border-right: $border-width solid $border-color;
  }
  .main {
    overflow-y: auto;
    .page-container {
      margin: 0 auto;
      max-width: $size-lg;
      padding: $layout-spacing-lg;
    }
  }
}
</style>
