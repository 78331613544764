import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBan as fadBan,
  faCheck as fadCheck,
  faCogs as fadCogs,
  faCopy as fadCopy,
  faExpandWide as fadExpandWide,
  faExclamationTriangle as fadExclamationTriangle,
  faExternalLink as fadExternalLink,
  faMegaphone as fadMegaphone,
  faPlus as fadPlus,
  faPrint as fadPrint,
  faTimes as fadTimes,
  faTv as fadTv,
  faUser as fadUser,
  faMicrophone as fadMicrophone,
  faHospitals as fadHospitals,
  faTrash as fadTrash,
  faTicket as fadTicket,
  faHospital as fadHospital,
  faUserHeadset as fadUserHeadset,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faTimes as falTimes,
} from '@fortawesome/pro-light-svg-icons';
import {
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faChevronDown as farChevronDown,
  faChevronUp as farChevronUp,
  faArrowLeft as farArrowLeft,
  faSearch as farSearch,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBan as fasBan,
  faPause as fasPause,
  faPlay as fasPlay,
  faStop as fasStop,
} from '@fortawesome/pro-solid-svg-icons';

// Duo tone icons
library.add(fadBan);
library.add(fadCheck);
library.add(fadCogs);
library.add(fadCopy);
library.add(fadExpandWide);
library.add(fadExclamationTriangle);
library.add(fadExternalLink);
library.add(fadMegaphone);
library.add(fadPlus);
library.add(fadPrint);
library.add(fadTimes);
library.add(fadTv);
library.add(fadUser);
library.add(fadMicrophone);
library.add(fadHospitals);
library.add(fadHospital);
library.add(fadUserHeadset);
library.add(fadTicket);
library.add(fadTrash);

// Light icons
library.add(falTimes);

// Regular icons
library.add(farChevronLeft);
library.add(farChevronRight);
library.add(farChevronDown);
library.add(farChevronUp);
library.add(farSearch);
library.add(farArrowLeft);

// Solid icons
library.add(fasBan);
library.add(fasPause);
library.add(fasPlay);
library.add(fasStop);
