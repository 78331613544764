import moment from 'moment';

const labels = {
  year: {
    short: ['a'],
    long: ['ano', 'anos'],
  },
  month: {
    short: ['m'],
    long: ['mês', 'meses'],
  },
  day: {
    short: ['d'],
    long: ['dia', 'dias'],
  },
};

function parseFormat(format) {
  if (!format) format = 'default';

  switch (format) {
    case 'compact':
      return 'Y:$S?|M:_$S?|D:_$S?';
    case 'compact:all':
      return 'Y:$S|M:_$S|D:_$S';
    case 'year':
      return 'Y:$ L';
    case 'year:short':
      return 'Y:$S';
    case 'default':
      return 'Y:$ L,?|M:_$ L,?|D:_$ L?';
    default:
      return format;
  }
}

function formatComponent(format, value, { short, long }) {
  if (value === 0 && format.endsWith('?')) return '';
  return format
    .replace('$', value.toString())
    .replace('S', short[0])
    .replace('L', value === 1 ? long[0] : long[1])
    .replace(/_/g, ' ')
    .replace('?', '');
}

export default (value, { format = null, auto = false } = {}) => {
  if (!value || value.length < 10 || value === 'Invalid date') return '';

  const date = moment(value);

  if (!date.isValid()) return '';

  const years = moment().diff(date, 'years');
  if (years > 0) {
    date.add(years, 'years');
  }

  const months = moment().diff(date, 'months');
  if (months > 0) {
    date.add(months, 'months');
  }

  const days = moment().diff(date, 'days');

  return parseFormat(format)
    .split('|')
    .map((v) => {
      const [type, str] = v.split(':');
      switch (type) {
        case 'Y':
          return formatComponent(str, years, labels.year);
        case 'M':
          return auto && years >= 5 ? null : formatComponent(str, months, labels.month);
        case 'D':
          return auto && years >= 5 ? null : formatComponent(str, days, labels.day);
        default:
          return null;
      }
    })
    .filter((v) => v !== null)
    .join('')
    .replace(/,$/, '')
    .trim();
};
