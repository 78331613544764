import { createStore } from 'vuex';
import { vuex as views } from '@/views';

const store = createStore({
  state: {
    now: Date.now(),
  },
  mutations: {
    startTimer(state) {
      state.now = Date.now();
      setTimeout(() => {
        state.now = Date.now();
        setInterval(() => {
          state.now = Date.now();
        }, 1000);
      }, 1000 - new Date().getMilliseconds());
    },
  },
  actions: {
    //
  },
  modules: views.modules,
  plugins: views.plugins,
});

export default store;
