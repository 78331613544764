<template>
  <div class="tile" :class="classes">
    <div class="tile-icon">
      <slot name="icon">
        <fa-icon :icon="icon" v-if="icon" />
      </slot>
    </div>
    <div class="tile-content">
      <slot></slot>
    </div>
    <div class="tile-action">
      <slot name="action"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    centered: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Array,
    },
    size: {
      type: String,
    },
  },
  computed: {
    classes() {
      const items = [];
      if (this.centered) {
        items.push('tile-centered');
      }
      if (this.size) {
        items.push(`tile-${this.size}`);
      }
      return items;
    },
  },
};
</script>
