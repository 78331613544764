import { v4 as uuidv4 } from 'uuid';
import Ably from 'ably/build/ably';
import { ticketApi } from '../ticket';

let service;

export const getService = async () => {
  if (!service) {
    const { data } = await ticketApi.get('/config');

    service = new Ably.Realtime({
      key: data.ably.apiKey,
      clientId: uuidv4(),
    });
  }

  return service;
};

export default {
  install(app) {
    app.config.globalProperties.$realtime = getService;
  },
};
