export default [
  {
    path: '/preference',
    name: 'preference',
    component: () => import(/* webpackChunkName: "preference" */ './Main.vue'),
    meta: {
      layout: 'device',
    },
  },
];
