<template>
  <header class="navbar main-nav">
    <section class="navbar-section">
      <router-link :to="{ name: 'home' }" class="logo">
        <img src="@/assets/svg/stenci-logo.svg" alt="Stenci Logo">
      </router-link>
    </section>
    <section class="navbar-center">
    </section>
    <section class="navbar-section">
      <div class="product-name">{{ title ? `${title} -` : '' }} Painel de chamadas</div>
    </section>
  </header>
</template>

<script>
export default {
  data() {
    return {
      title: '',
    };
  },
  mounted() {
    this.title = localStorage.hospitalName;
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.master-layout {
  .main-nav {
    background-color: #fff;
    height: $header-size;
    padding: 0 1rem;
    text-align: center;

    &.navbar {
      flex-wrap: nowrap;

      .navbar-section {
        //flex: 0 2rem;
      }

      .navbar-center {
        justify-content: center;
        flex: 0 1 auto;
      }
    }

    .page-title {
      font-weight: 600;
      text-transform: uppercase;
    }

    .logo {
      img {
        display: block;
        height: 1.6rem;
      }

      &:focus {
        box-shadow: none;
      }
    }

    svg {
      font-size: 1.3rem;
      display: block;
      width: auto;
    }

    .product-name {
      color: $gray-color-dark;
      font-size: $font-size-lg;
      font-weight: 600;
    }
  }
}
</style>
