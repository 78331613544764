import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import registerPlugins from './plugins';
import registerDirectives from './directives';
import registerComponents from './components';
import registerFilters from './filters';
import './assets/scss/main.scss';
import './assets/fontawesome';

const app = createApp(App);

app.use(store);
app.use(router);

registerPlugins(app, { router, store });
registerDirectives(app);
registerComponents(app);
registerFilters(app);

store.commit('startTimer');

app.mount('#app');
