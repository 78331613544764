<template>
  <router-view/>
  <button
    class="btn btn-action btn-icon btn-full-screen"
    @click="toggleFullScreen"
  ><fa-icon :icon="['fad', 'expand-wide']"></fa-icon></button>
</template>

<script>
import { toggleFullScreen } from '../utils/app';

export default {
  data() {
    return {
      ready: false,
    };
  },
  mounted() {
    this.ready = true;
    document.body.classList.add('device-layout');
  },
  unmounted() {
    document.body.classList.remove('device-layout');
  },
  methods: {
    toggleFullScreen() {
      toggleFullScreen();
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables';

.device-layout {
  background-color: $bg-color;
  .btn-full-screen {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    z-index: $zindex-1;
  }
}
</style>
